import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { getLocalStorage } from '~/utils/storage';

enum UTMEnum {
  utm_source = 'utmSource',
  utm_campaign = 'utmCampaign',
  utm_medium = 'utmMedium',
  utm_term = 'utmTerm',
  utm_content = 'utmContent',
}

const UTM_LOCALSTORAGE_KEY = 'utm';
const UTM_LOCALSTORAGE_TTL = 7 * 24 * 60 * 60 * 1000; // 7 days in ms

export type UTMType = {
  [key in UTMEnum]?: string;
};

export type LocalUTM = {
  time: number;
  utm: UTMType;
};

const useUTM = (): UTMType => {
  const { query } = useRouter();

  return useMemo(() => {
    let utm: UTMType = {};

    for (const key of Object.keys(query)) {
      if (UTMEnum[key]) {
        const value = (Array.isArray(query[key]) ? query[key][0] : query[key]) as string;
        if (value) {
          utm[UTMEnum[key]] = value;
        }
      }
    }

    const lStorage = getLocalStorage();

    if (lStorage) {
      let localUTM: LocalUTM;

      // if we didn't found any utm in url
      if (!Object.keys(utm).length) {
        // try to load UTMs from localstorage
        try {
          localUTM = JSON.parse(lStorage.getItem(UTM_LOCALSTORAGE_KEY));
        } catch (e) {
          // do nothing
        }

        // make sure potentially stored utm did not expired
        if (localUTM && localUTM.time >= Date.now() - UTM_LOCALSTORAGE_TTL) {
          // if it did not expired, we can reuse them
          utm = localUTM.utm;
        } else {
          // otherwise, remove them
          lStorage.removeItem(UTM_LOCALSTORAGE_KEY);
        }
      } else {
        localUTM = { time: Date.now(), utm };
        // if we found utm in url, store them in localstorage
        lStorage.setItem(UTM_LOCALSTORAGE_KEY, JSON.stringify(localUTM));
      }
    }

    return utm;
  }, []);
};

export default useUTM;
