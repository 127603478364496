import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';

import { getEnv } from '~/utils/env';
import { isServerSide } from '~/utils/next';

// eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
const { version } = require('../../package.json');

export const initSentry = (): void => {
  const NEXT_PUBLIC_SENTRY_DSN = getEnv('NEXT_PUBLIC_SENTRY_DSN');

  if (NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = [];

    if (isServerSide()) {
      const NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR = getEnv('NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR');

      if (NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
        // For Node.js, rewrite Error.stack to use relative paths, so that source
        // maps starting with ~/_next map to files in Error.stack with path
        // app:///_next
        integrations.push(
          new RewriteFrames({
            iteratee: frame => {
              frame.filename = frame.filename.replace(NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR, 'app:///');
              frame.filename = frame.filename.replace('.next', '_next');
              return frame;
            },
          }),
        );
      }
    }

    Sentry.init({
      integrations,
      enabled: true,
      environment: getEnv('ENV'),
      dsn: NEXT_PUBLIC_SENTRY_DSN,
      release: `iziwork-jobs@${version}`,
      ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
    });
  }
};
