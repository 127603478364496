import { useContext, useMemo } from 'react';

import { TrackingContext } from '~/contexts/TrackingContext';

const PREFIX_SOURCE = 'IZIWORK_JOBS_';
const DEFAULT_SOURCE = 'ORGANIC';

export const useSource = (): string => {
  const {
    utms: { utmSource },
  } = useContext(TrackingContext);

  return useMemo(() => `${PREFIX_SOURCE}${utmSource?.toUpperCase() || DEFAULT_SOURCE}`, [utmSource]);
};
