import { PlaceAutocompleteResult, PlaceData } from '@googlemaps/google-maps-services-js';
import { buildAddressFromGoogleInput, TAddress, TAvailableCountry } from '@iziwork/l10n';

export interface AddressLocation {
  latitude: number;
  longitude: number;
}

export interface AddressWithNameAndLocation extends TAddress {
  name: string;
  location: AddressLocation;
}

export interface Prediction {
  name: string;
  placeId: string;
}

const locale = { fr: ', France', it: ', Italia' };

export const formatPrediction = (place: PlaceAutocompleteResult, country: string): Prediction => ({
  placeId: place.place_id,
  name: place.description.replace(locale[country], ''),
});

export const stringifyAddress = ({ name, zipcode, location }: AddressWithNameAndLocation): string =>
  `${[name, zipcode].filter(Boolean).join('+')}@${location.latitude},${location.longitude}`;

export const parseAddress = (address: string): AddressWithNameAndLocation | undefined => {
  const [cityWithZipcode, location] = address.split('@');
  const [name, zipcode = ''] = cityWithZipcode.split('+');
  const [latitude, longitude] = location.split(',');
  if (name && parseFloat(latitude) && parseFloat(longitude)) {
    return {
      name,
      zipcode,
      location: { latitude: parseFloat(latitude), longitude: parseFloat(longitude) },
    };
  }
  return undefined;
};

export const placeToAddressWithCityAndLocation = (place: PlaceData, country: string): AddressWithNameAndLocation => {
  const address = buildAddressFromGoogleInput({
    googleAddress: place.address_components,
    countryCode: country.toUpperCase() as TAvailableCountry,
  });

  return {
    ...address,
    name: address.city || address.administrativeLevel3,
    country: address.country.toUpperCase(),
    location: {
      latitude: place.geometry.location.lat,
      longitude: place.geometry.location.lng,
    },
  };
};
