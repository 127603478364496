import { getJobsPublicApiEndpoint } from '~/config/jobs';
import { getEnv } from '~/utils/env';

export const getGcpProjectId = (): string | undefined => getEnv('GCP_PROJECT_ID');

export const getGoogleApiKey = (): string | undefined => getEnv('GOOGLE_API_KEY');

export function getRecaptchaInvisibleSiteKey(): string {
  return getRecaptchaSiteKey({ invisible: true });
}

export function getRecaptchaSiteKey({ invisible = false } = {}): string {
  const sitekey = invisible
    ? getEnv('NEXT_PUBLIC_GOOGLE_RECAPTCHA_INVISIBLE_SITEKEY')
    : getEnv('NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY');

  if (!sitekey) {
    console.warn('Google captcha site key is not provided.');
    return '';
  }

  return sitekey;
}

export const getGMapsApiUrl = (country: string, functionName: string): string =>
  `${getJobsPublicApiEndpoint(country)}/gmaps/${functionName}`;
