import { PlaceAutocompleteResult, PlaceAutocompleteType } from '@googlemaps/google-maps-services-js';
// eslint-disable-next-line no-restricted-imports
import axios from 'axios';
import { useCallback, useState } from 'react';

import { getGMapsApiUrl } from '~/config';
import { useCacheFromCallback, useDebouncedCallback, useLocale } from '~/hooks';
import { formatPrediction, Prediction } from '~/utils/address';

type GetAutocompleteSuggestion = (input: string) => Promise<void>;

export type UseGooglePredictionProps = { debounceDelay?: number; type?: PlaceAutocompleteType };
export type UseGooglePredictionResult = [Prediction[] | null, boolean, GetAutocompleteSuggestion];

export const usePredictions = ({
  type,
  debounceDelay = 800,
}: UseGooglePredictionProps = {}): UseGooglePredictionResult => {
  const { country } = useLocale();
  const [loading, setLoading] = useState(false);
  const [predictions, setPredictions] = useState<Prediction[]>(null);

  const findPredictions = useCacheFromCallback(async input => {
    try {
      const { data } = await axios.post<PlaceAutocompleteResult[] | null>(getGMapsApiUrl(country, 'autocomplete'), {
        input,
        type,
      });
      if (data) {
        return data.map(prediction => formatPrediction(prediction, country));
      }
    } catch (e) {
      console.error(e);
    }
    return [];
  });

  const [debouncePredictions, cancelDebouncePredictions] = useDebouncedCallback(async (input: string) => {
    setLoading(true);
    setPredictions(await findPredictions(input));
    setLoading(false);
  }, debounceDelay);

  const getPredictions = useCallback(async (input: string) => {
    if (input) {
      await debouncePredictions(input);
    } else {
      cancelDebouncePredictions();
      setPredictions([]);
    }
  }, []);

  return [predictions, loading, getPredictions];
};
