import { useRouter } from 'next/router';
import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import useUTM from '~/hooks/useUTM';
import { extractLocaleData } from '~/locale';

import { EVENTS, TEvents } from './constants';

export type TrackedFilterValue = {
  default: boolean;
  value: unknown;
};

export type JobFiltersTrackedValues = {
  job: string;
  city: string;
  distance: TrackedFilterValue;
  experience: TrackedFilterValue;
  sort: TrackedFilterValue;
  type: TrackedFilterValue;
  resultsNumber: number;
  pageNumber: number;
};

type JobOfferParams = { refId: string; jobTitle: string };
type ApplicationFunnelParams = JobOfferParams & { email: string };

type TrackingContextType = {
  sessionId: string;
  track: (name: TEvents, params?: Record<string, any>) => void;
  trackPage: (name: TEvents, params?: Record<string, any>) => void;
  trackActiveMissionPageChange: (params: JobFiltersTrackedValues) => void;
  trackJobOfferClicked: (params: JobFiltersTrackedValues & JobOfferParams) => void;
  trackSearchResultsDisplayed: (params: JobFiltersTrackedValues) => void;
  trackApplyButtonClicked: (params: JobOfferParams) => void;
  trackEmailSubmitted: (params: ApplicationFunnelParams) => void;
  trackApplicationSubmitted: (params: ApplicationFunnelParams) => void;
  utms: ReturnType<typeof useUTM>;
  referer: string | null;
};

export const TrackingContext = createContext<TrackingContextType>({
  sessionId: '',
  track: null,
  trackPage: null,
  trackApplyButtonClicked: null,
  trackEmailSubmitted: null,
  trackApplicationSubmitted: null,
  trackActiveMissionPageChange: null,
  trackJobOfferClicked: null,
  trackSearchResultsDisplayed: null,
  utms: null,
  referer: null,
});

type TrackingContextProviderProps = {
  children: ReactNode;
};

export function TrackingContextProvider({ children }: TrackingContextProviderProps) {
  const sessionId = useMemo(() => uuidv4(), []);
  const { locale, asPath } = useRouter();
  const utms = useUTM();

  const [referer, setReferer] = useState<string>(null);
  const [currentPage, setCurrentPage] = useState<string>(null);

  useEffect(() => {
    setReferer(currentPage);
    setCurrentPage(`${window.location.origin}${globalThis.location?.pathname}`);
  }, [asPath]);

  const track = (name: TEvents, params?: Record<string, any>) => {
    window.analytics?.track(name, {
      sessionId,
      ...params,
      ...extractLocaleData(locale),
      ...utms,
    });
  };

  const trackPage = (name: TEvents, params?: Record<string, any>) => {
    // at this point, the referer has not yet been updated
    // so the referer (previousPageUrl) is the value of the currentPage variable
    track(name, {
      pageUrl: `${window.location.origin}${window.location.pathname}`,
      previousPageUrl: currentPage,
      ...params,
    });
  };

  const trackActiveMissionPageChange = (params: { pageNumber: number }) => {
    track(EVENTS.activeMissionsPageChanged, params);
  };

  const trackJobOfferClicked = (params: JobFiltersTrackedValues & JobOfferParams) => {
    track(EVENTS.jobOfferClicked, params);
  };

  const trackSearchResultsDisplayed = (params: JobFiltersTrackedValues) => {
    track(EVENTS.searchResultsDisplayed, params);
  };

  const trackApplyButtonClicked = (params: JobOfferParams) => {
    track(EVENTS.applyButtonClicked, params);
  };
  const trackEmailSubmitted = (params: ApplicationFunnelParams) => {
    track(EVENTS.emailSubmitted, params);
  };
  const trackApplicationSubmitted = (params: ApplicationFunnelParams) => {
    track(EVENTS.applicationSubmitted, params);
  };

  return (
    <TrackingContext.Provider
      value={{
        sessionId,
        track,
        trackPage,
        trackActiveMissionPageChange,
        trackJobOfferClicked,
        trackSearchResultsDisplayed,
        trackApplyButtonClicked,
        trackEmailSubmitted,
        trackApplicationSubmitted,
        utms,
        referer,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
}
